import Vue from "vue";
import VueRouter from "vue-router";
import home from "../views/home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/jqh5",
    name: "jqh5",
    component: () => import("../views/situation/jqh5.vue"),
    meta: { title: "jqh5" },
  },
  {
    path: "/videoh5",
    name: "videoh5",
    component: () => import("../views/situation/videoh5.vue"),
    meta: { title: "videoh5" },
  },
  {
    path: "/",
    name: "home",
    component: home,
    redirect: "/homePage",
    children: [
      {
        path: "/homePage",
        name: "homePage",
        component: () => import("../views/homePage.vue"),
        meta: { title: "网站首页" },
      },
      {
        path: "/general",
        name: "general",
        component: () => import("../views/general.vue"),
        meta: { title: "林区概况" },
        redirect: "/generalOne",
        children: [
          {
            path: "/generalOne",
            name: "generalOne",
            component: () => import("../views/situation/general.vue"),
            meta: { title: "林区概况" },
          },
          {
            path: "/forestAudio",
            name: "forestAudio",
            component: () => import("../views/situation/forestAudio.vue"),
            meta: { title: "林区影音" },
          },
          {
            path: "/forestInformation",
            name: "forestInformation",
            component: () => import("../views/situation/forestInformation.vue"),
            meta: { title: "林区资讯" },
          },
          {
            path: "/seacher",
            name: "seacher",
            component: () => import("../views/situation/seacher.vue"),
            meta: { title: "搜索" },
          },
          {
            path: "/lqxxInfo",
            name: "lqxxInfo",
            component: () => import("../views/situation/lqxxInfo.vue"),
            meta: { title: "林区信息" },
          },
          {
            path: "/jcfw",
            name: "jcfw",
            component: () => import("../views/situation/jcfw.vue"),
            meta: { title: "基础服务" },
          },
        ],
      },
      {
        path: "/lqxx",
        name: "lqxx",
        component: () => import("../views/situation/lqxx.vue"),
        meta: { title: "林区资讯" },
      },
      {
        path: "/lqxxwh",
        name: "lqxxwh",
        component: () => import("../views/situation/lqxxwh.vue"),
        meta: { title: "文化" },
      },
      {
        path: "/lqxxst",
        name: "lqxxst",
        component: () => import("../views/situation/lqxxwh.vue"),
        meta: { title: "生态" },
      },

      {
        path: "/bzjdxx",
        name: "bzjdxx",
        component: () => import("../views/situation/bzjdxx.vue"),
        meta: { title: "标志景点" },
      },
      {
        path: "/district",
        name: "district",
        component: () => import("../views/district.vue"),
        meta: { title: "醉美林区" },
        redirect: "/landmarkspot",
        children: [
          {
            path: "/landmarkspot",
            name: "landmarkspot",
            component: () => import("../views/zumeidistrict/landmarkspot.vue"),
            meta: { title: "标志景点" },
          },
          {
            path: "/experiencetheme",
            name: "experiencetheme",
            component: () =>
              import("../views/zumeidistrict/experiencetheme.vue"),
            meta: { title: "体验主题" },
          },
          {
            path: "/culturalforestarea",
            name: "culturalforestarea",
            component: () =>
              import("../views/zumeidistrict/culturalforestarea.vue"),
            meta: { title: "文化林区" },
          },
          {
            path: "/ecologicalforestarea",
            name: "ecologicalforestarea",
            component: () =>
              import("../views/zumeidistrict/ecologicalforestarea.vue"),
            meta: { title: "生态林区" },
          },
          {
            path: "/foodzone",
            name: "foodzone",
            component: () => import("../views/zumeidistrict/foodzone.vue"),
            meta: { title: "美食专区" },
          },
          {
            path: "/jdzone",
            name: "jdzone",
            component: () => import("../views/zumeidistrict/jdzone.vue"),
            meta: { title: "首页景点专区" },
          },
          {
            path: "/zxzone",
            name: "zxzone",
            component: () => import("../views/zumeidistrict/zxzone.vue"),
            meta: { title: "资讯列表专区" },
          },
          {
            path: "/twglzone",
            name: "twglzone",
            component: () => import("../views/zumeidistrict/twglzone.vue"),
            meta: { title: "图文攻略专区" },
          },
        ],
      },
      {
        path: "/event",
        name: "event",
        component: () => import("../views/event.vue"),
        meta: { title: "活动盛典" },
        redirect: "/eventGala",
        children: [
          {
            path: "/eventGala",
            name: "eventGala",
            component: () => import("../views/eventGala.vue"),
            meta: { title: "活动盛典" },
          },
          {
            path: "/specialactivities",
            name: "specialactivities",
            component: () => import("../views/specialactivities.vue"),
            meta: { title: "专题活动" },
          },
        ],
      },
      {
        path: "/hdsdXq",
        name: "hdsdXq",
        component: () => import("../views/hdsdXq.vue"),
        meta: { title: "活动盛典详情" },
      },
      {
        path: "/lqdhxq",
        name: "lqdhxq",
        component: () => import("../views/situation/lqdhxq.vue"),
        meta: { title: "林区导航详情" },
      },
      {
        path: "/meishi",
        name: "meishi",
        component: () => import("../views/situation/meishi.vue"),
        meta: { title: "美食详情" },
      },
      {
        path: "/zthdXq",
        name: "zthdXq",
        component: () => import("../views/zthdXq.vue"),
        meta: { title: "专题活动详情" },
      },
      {
        path: "/notice",
        name: "notice",
        component: () => import("../views/notice.vue"),
        meta: { title: "通知公告" },
      },

      {
        path: "/ticketReservation",
        name: "ticketReservation",
        component: () => import("../views/ticketReservation.vue"),
        meta: { title: "酒店预订" },
      },
      {
        path: "/piaowuyuding",
        name: "piaowuyuding",
        component: () => import("../views/piaowuyuding.vue"),
        meta: { title: "票务预订" },
      },
      {
        path: "/pwydDetails",
        name: "pwydDetails",
        component: () => import("../views/pwydDetails.vue"),
        meta: { title: "票务预订详情" },
      },
      {
        path: "/jdxq",
        name: "jdxq",
        component: () => import("../views/jdxq.vue"),
        meta: { title: "景点详情" },
      },
      {
        path: "/gywm",
        name: "gywm",
        component: () => import("../views/gywm.vue"),
        meta: { title: "关于我们" },
      },
      {
        path: "/jiaot",
        name: "jiaot",
        component: () => import("../views/jiaot.vue"),
        meta: { title: "交通" },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
