<template>
  <div class="conter">
    <div class="header">
      <el-menu
        :default-active="activeIndex"
        :collapse-transition="false"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
      >
        <el-menu-item index="/homePage">网站首页</el-menu-item>
        <el-submenu index="/general" class="mySubMenu1">
          <template slot="title">林区概况</template>
          <el-menu-item index="/general">林区概况</el-menu-item>
          <el-menu-item index="/forestInformation">林区资讯</el-menu-item>
          <el-menu-item index="/forestAudio">林区影音</el-menu-item>
          <el-menu-item index="/lqxxInfo">林区信息</el-menu-item>
          <el-menu-item index="/jcfw">基础服务</el-menu-item>
        </el-submenu>
        <!-- <el-menu-item index="/general" >林区概况</el-menu-item> -->
        <!-- <el-menu-item index="/district" >醉美林区</el-menu-item> -->
        <el-submenu index="/landmarkspot" class="mySubMenu1">
          <template slot="title">醉美林区</template>
          <el-menu-item index="/landmarkspot">标志景点</el-menu-item>
          <el-menu-item index="/experiencetheme">体验主题</el-menu-item>
          <el-menu-item index="/culturalforestarea">文化林区</el-menu-item>
          <el-menu-item index="/ecologicalforestarea">生态林区</el-menu-item>
          <!-- <el-menu-item index="/foodzone">美食专区</el-menu-item> -->
        </el-submenu>
        <el-submenu index="/eventGala" class="mySubMenu1">
          <template slot="title">活动盛典</template>
          <el-menu-item index="/eventGala">活动盛典</el-menu-item>
          <el-menu-item index="/specialactivities">专题活动</el-menu-item>
        </el-submenu>

        <!-- <el-menu-item index="/ticketReservation" >票务预订</el-menu-item> -->
        <el-submenu index="/ticketReservation" class="mySubMenu">
          <template slot="title"><div>在线预订</div></template>
          <!--          <el-menu-item index="/ticketReservation">餐饮预订</el-menu-item>-->
          <el-menu-item index="/ticketReservation">酒店预订</el-menu-item>
          <el-menu-item index="/pwydDetails">票务预订</el-menu-item>
          <!--          <el-menu-item index="/ticketReservation">露营预订</el-menu-item>-->
          <!--          <el-menu-item index="/ticketReservation">拓展预订</el-menu-item>-->
          <!--          <el-menu-item index="/ticketReservation">其他</el-menu-item>-->
        </el-submenu>
        <el-menu-item index="/notice">通知公告</el-menu-item>
      </el-menu>
      <div class="elflex">
        <el-input
          clearable
          size="small"
          v-model="seacherName"
          style="width: 300px"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="onSeacher"
          ></el-button
        ></el-input>
      </div>
      <div style="position: absolute; left: 20px; top: 0px; color: #fff">
        <div style="width: 260px; height: 80px" class="felx">
          <div style="width: 50px; height: 50px">
            <img style="width: 100%; height: 100%" :src="weather.img" alt="" />
          </div>
          <div class="">
            <div class="felx">
              <div style="font-weight: 600; font-size: 20px">
                {{ weather.wendu }}
              </div>
              <div style="color: #fff; font-size: 13px; margin-left: 5px">
                {{ weather.fx }}{{ weather.fl }}
              </div>
            </div>
            <div class="felx" style="font-size: 13px">
              <div style="color: #fff; font-weight: 600">
                {{ weather.week }}
              </div>
              <div style="color: #fff">{{ weather.date }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <el-carousel class="model1" :interval="5000" arrow="always" height="600px;">
      <el-carousel-item v-for="(item,index) in header" :key="index" class="sywz" >
      <img class="imdt" :src="item.url" />
        <p class="medium1" >{{item.name}}</p>
        <h3 class="medium" >{{item.con}}</h3>
      </el-carousel-item>
    </el-carousel> -->
    <div class="moddel">
      <router-view />
    </div>
    <div class="bottom">
      <div class="sy">
        <div class="gzlx">
          <div
            class="jtlx"
            v-for="(item, index) in list"
            :key="index"
            @click="gywm(item)"
          >
            {{ item.name }}
          </div>
        </div>
        <div>
          <div style="display: flex;flex-wrap: wrap;margin-top: 10px;" class="cs">
            <div style="color: #fff;padding-bottom: 5px;border-right:1px solid #fff;padding: 0 10px;margin-bottom: 15px;"   v-for="(item, index) in phoneDate">
            {{ item.name }}:{{ item.phone }}
          </div>
          </div>
          <div class="rwm" style="position: absolute;bottom: 50px;right: 13%;">
            <img
              src="../assets/image/erweima.png"
              style="width: 100px; height: 100px"
            />
          </div>
          <div style="color: #fff;padding-bottom: 5px;margin-top: 20px;padding-left: 10px;text-align: center;">蜀ICP备2022013448号-3</div>
        </div>


      </div>
    </div>
  </div>
</template>
<script>
import { service } from "@/request";
export default {
  data() {
    return {
      header: {},
      activeIndex: "/homePage",
      phone: "",
      seacherName: "",
      weather: {},
      list: [
        {
          name: "首页",
        },
        {
          name: "景区",
        },
        {
          name: "攻略",
        },
        {
          name: "美食",
        },
        {
          name: "住宿",
        },
        {
          name: "交通",
        },
        {
          name: "在线预订",
        },
        {
          name: "关于我们",
        },
      ],
      phoneDate: [],
    };
  },
  created() {
    this.getTime();
    this.Carousel();
    this.getPhone();
  },
  mounted() {
    this.Carousel();
  },
  methods: {
    getTime() {
      var date = new Date();
      var year = date.getFullYear(); //月份从0~11，所以加一
      let month = date.getMonth();
      console.log("month", month);
      var dateArr = [
        date.getMonth() + 1,
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
      ];
      //如果格式是MM则需要此步骤，如果是M格式则此循环注释掉
      for (var i = 0; i < dateArr.length; i++) {
        if (dateArr[i] >= 1 && dateArr[i] <= 9) {
          dateArr[i] = "0" + dateArr[i];
        }
      }
      var strDate = year + "-" + dateArr[0] + "-" + dateArr[1];
      //此处可以拿外部的变量接收  strDate:2022-05-01 13:25:30
      //this.date = strDate;
      console.log("strDate", strDate);
      this.getWeather(strDate);
    },
    // 天气
    getWeather(strDate) {
      service({
        url: "/api/Index/weather",
        method: "post",
        data: { date: strDate },
      }).then((res) => {
        if (res.code == 200) {
          if (res.data) {
            this.weather = res.data[0];
          } else {
            this.weather = {};
          }
        }
      });
    },
    onSeacher() {
      if (this.seacherName == "") {
        this.$message.warning("输入内容不能为空~");
        return;
      }
      this.$router.push({
        path: "/seacher",
        query: {
          name: this.seacherName,
        },
      });
      this.seacherName == "";
    },
    pageTo(type) {
      if (type === 1) {
      }
    },
    gywm(item) {
      if (item.name == "首页") {
        this.$router.push({
          path: "/homePage",
        });
      } else if (item.name == "景区") {
        this.$router.push({
          path: "/jdzone",
        });
      } else if (item.name == "攻略") {
        this.$router.push({
          path: "/twglzone",
        });
      } else if (item.name == "美食") {
        this.$router.push({
          path: "/foodzone",
        });
      } else if (item.name == "住宿") {
        this.$router.push({
          path: "/ticketReservation",
        });
      } else if (item.name == "在线预订") {
        this.$router.push({
          path: "/pwydDetails",
        });
      } else if (item.name == "关于我们") {
        this.$router.push({
          path: "/gywm",
          query: {
            name: item.name,
          },
        });
      } else if (item.name == "交通") {
        this.$router.push({
          path: "/jiaot",
          query: {
            name: item.name,
          },
        });
      }
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      this.activeIndex = key;
      this.$router.push(key); // 跳转到对应的路由
    },
    //轮播图
    Carousel() {
      service({
        url: "/api/Attract/banlist",
        method: "post",
        data: {},
      }).then((data) => {
        if (data.code == 200) {
          this.header = data.data;
        }
      });
    },
    getPhone() {
      let that = this;
      service({
        url: "/api/alarm/getPhone",
        method: "post",
        data: {},
      }).then((data) => {
        if (data.code == 200) {
          var phone = data.data;
          console.log(phone);
          this.phoneDate = phone;
          // that.list.push({
          //   name: "一键报警电话:" + phone,
          // });
        }
      });
    },
  },
};
</script>
<style scoped>
/deep/ .el-menu--horizontal .el-submenu__title:hover {
  background-color: red;
}
.nameSty {
  position: absolute;
  left: 5rem;
  bottom: 10rem;
  font-size: 50px;
  color: white;
  /* text-decoration-line: underline; */
  font-weight: 900;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 48px;
  line-height: 100%;
  display: flex;
  align-items: center;
  flex: none;
  order: 1;
  align-self: stretch;
}
/deep/ .el-carousel__container {
  position: relative;
  height: 600px;
}
.imdt {
  width: 100%;
  height: 100%;
}
.medium {
  position: absolute;
  left: 5rem;
  bottom: 10rem;
  font-size: 50px;
  color: white;
  /* text-decoration-line: underline; */
  font-weight: 900;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 48px;
  line-height: 100%;
  display: flex;
  align-items: center;
  flex: none;
  order: 1;
  align-self: stretch;
}
.medium1 {
  position: absolute;
  left: 5rem;
  bottom: 15rem;
  font-size: 50px;
  color: white;
  /* text-decoration-line: underline; */
  font-weight: 900;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 48px;
  line-height: 100%;
  display: flex;
  align-items: center;
  flex: none;
  order: 1;
  align-self: stretch;
}
.model1 {
  width: 99.9%;
  height: 600px;
  /*background: url("../assets/image/dpm1.png") no-repeat;*/
  background-size: 100% 100%;
}
.header {
  width: 99.9%;
  height: 80px;
  display: flex;
  justify-content: center;
  background: #207b58;
}

/deep/ .el-menu--horizontal > .el-menu-item {
  float: left;
  height: 80px;
  line-height: 80px;
  margin: 0;
  font-size: 18px !important;
  border-bottom: 2px solid transparent;
  color: #f9fafb;
}
/deep/ .el-menu.el-menu--horizontal {
  border-bottom: solid 0px #e6e6e6;
}
/deep/ .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: #043c1f;
  color: #fff;
}
/deep/ .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: #043c1f;
}
/deep/ .el-menu--horizontal > .el-submenu .el-submenu__title {
  height: 80px;
  line-height: 80px;
  border-bottom: 2px solid transparent;
  color: #fff !important;
  font-size: 18px !important;
}
/deep/ .el-menu--horizontal > .el-submenu:focus.el-submenu__title,
.el-menu--horizontal > .el-submenu:hover .el-submenu__title {
  color: #19191a !important;
}
/deep/ .el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 0px solid #409eff;
  color: #fff;
}
/deep/ .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: 0px solid #409eff;
  color: #fff;
}
/deep/ .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: #043c1f;
}
/deep/ .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: #043c1f;
  color: #fff;
}
/deep/ .el-menu--horizontal > .el-submenu:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: #043c1f;
  color: #fff;
}
/deep/ .el-submenu__title i {
  color: #909399;
  display: none;
}
.el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .el-submenu__title {
  background-color: #043c1f;
  background: rgba(0, 0, 0, 0.73) !important;
  float: none;
  height: 36px;
  line-height: 36px;
  padding: 0 10px;
  color: #ffffff;
  font-size: 16px !important;
}
/deep/ .el-menu--horizontal .el-menu .el-menu-item.is-active,
.el-menu--horizontal .el-menu .el-submenu.is-active > .el-submenu__title {
  color: #ffffff;
}

.moddel {
}
.bottom {
  width: 99.9%;
  height: 200px;
  /* position: absolute; */
  /* left: 0; */
  /* bottom: 0; */
  background: #1f1f1f;
  display: flex;
  justify-content: center;
  position: relative;
}
.sy {
  width: 70%;
  height: 100px;
}
.gzlx {
  display: flex;
}
.jtlx {
  padding: 10px 20px;
  display: inline-block;
  color: #fff;
  cursor: pointer;
}
.bah {
  color: #fff;
  font-size: 14px;
  margin-left: 20px;
  /* margin-top: 40px; */
  display: inline-block;
}
.rwm {
  text-align: right;
  float: right;
  display: inline-block;
}
/deep/ .el-menu--horizontal .el-menu .el-menu-item.is-active,
.el-menu--horizontal .el-menu .el-submenu.is-active > .el-submenu__title {
  color: #fff;
}

/deep/ .el-menu--horizontal .el-menu-item:not(.is-disabled) :focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  outline: 0;
  color: #42cf99;
}
.elflex {
  position: absolute;
  top: 24px;
  right: 6%;
}
</style>
<style>
.el-menu {
  list-style: none !important;
  position: relative !important;
  margin: 0 !important;
  padding-left: 0 !important;
  margin-top: 0 !important;
  padding: 0 !important;
  min-width: 96px !important;
  background: #207b58 !important;
  color: #fff !important;
}
.el-menu--popup {
  z-index: 100 !important;
  min-width: 112px !important;
  border: none !important;
  border-radius: 2px !important;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) !important;
  margin-top: 0px !important;
  text-align: center;
  /* border: 1px solid red !important; */
}
.el-menu--horizontal .el-menu .el-menu-item.is-active,
.el-menu--horizontal .el-menu .el-submenu.is-active > .el-submenu__title {
  color: #fff;
}
.el-menu--horizontal .el-menu .el-menu-item.is-active,
.el-menu--horizontal .el-menu .el-submenu.is-active > .el-submenu__title {
  color: #fff !important;
}
.felx {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.cs div:last-child{
  border-right: transparent !important;
}
.cs div:nth-child(3){
  /* border-right: transparent !important; */
}
</style>
