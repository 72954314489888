import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from "axios";
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import VueCarousel from 'vue-carousel';
import VueImageLightbox from 'vue-image-lightbox';
import VueImageZoom from 'vue-image-zoom';
import BaiduMap from 'vue-baidu-map'

Vue.use(VueImageZoom);

// import Viewer from 'v-viewer'
// import 'viewerjs/dist/viewer.css'

Vue.use(VueCarousel);
Vue.use(VueImageLightbox);
// Vue.use(Viewer)
Vue.prototype.$axios = axios;
Vue.prototype.$video = Video;
Vue.use(ElementUI);
Vue.config.productionTip = false

Vue.use(BaiduMap, {
  ak: 'p5XmRlSoHFel6p6Y0TCbcEsN8XkXdDzV',
})
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
