import Vue from "vue";
import axios from "axios";
import { Message } from "element-ui";
// import Qs from "querystring";
import vm from "@/main.js";

let loading;
axios.defaults.withCredentials = true;
// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
  },
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    // loading = Vue.prototype.$loading({
    //   lock: true,
    //   text: "Loading",
    //   spinner: "el-icon-loading",
    //   background: "rgba(0, 0, 0, 0.7)",
    // });
    if (localStorage.getItem("token")) {
      config.headers.Authorization = `${localStorage.getItem("token")}`;
      config.headers["token"] = `${localStorage.getItem("token")}`;
      // config.headers["userId"] = `${localStorage.userId}`;
    }
    if (config.method === "post") {
      config.data = JSON.stringify({
        ...config.data,
      });
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// response拦截器
let num = 0;
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res) {
      // loading.close();
      return res;
    } else if (res.code == 200) {
      // loading.close();
      return res;
    } else if (res.code === 500) {
      // loading.close();
      Message({
        message: res.message,
        showClose: true,
        type: "error",
        duration: 10 * 1000,
      });
      return res;
    } else if (res.code === 1 || res.code === 2) {
      // loading.close();
      Message({
        message: res.message,
        showClose: true,
        type: "error",
        duration: 3 * 1000,
      });
      // return Promise.reject(new Error((res && res.msg) ? res.msg : 'Error'));
      return Promise.reject(res);
    } else if (res.code === 401) {
      // loading.close();
      Message({
        message: res.message,
        showClose: true,
        type: "error",
        duration: 3 * 1000,
      });
      // return Promise.reject(new Error((res && res.msg) ? res.msg : 'Error'));
      return Promise.reject(res);
    } else if (res.code === 6) {
      // loading.close();
      Message({
        message: res.message,
        showClose: true,
        type: "error",
        duration: 3 * 1000,
      });
      // return Promise.reject(new Error((res && res.msg) ? res.msg : 'Error'));
      return Promise.reject(res);
    } else {
      // loading.close();
      Message({
        message: "连接超时",
        showClose: true,
        type: "error",
        duration: 3 * 1000,
      });
      // return Promise.reject(new Error((res && res.msg) ? res.msg : 'Error'));
      return Promise.reject(res);
    }
  },
  (error) => {
    // loading.close();
    Message({
      message: "服务器异常",
      type: "error",
      showClose: true,
      duration: 3 * 1000,
    });
    return Promise.reject(error);
  }
);
export { service };
